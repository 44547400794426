export const apiUrl = process.env.NEXT_PUBLIC_SITE_URL;

export const printObject = (object: Object): string => {
  let result = "";

  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      //@ts-ignore
      result += key + " " + object[key] + "  -  ";
    }
  }

  return result;
};

//http://techslides.com/convert-csv-to-json-in-javascript
export function csvJSON(csv: string) {
  try {
    var lines = csv.replace("\r\n", "\n").replace("\r", "\n").split("\n");
    let splitter = ";";
    var result = [];
    var headers = lines[0].split(splitter);

    if (headers.length === 1) {
      splitter = ",";
      var headers = lines[0].split(splitter);
    }
    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(splitter);

      if (currentline.length > 1) {
        for (var j = 0; j < headers.length; j++) {
          // @ts-ignore
          // )
          obj[headers[j]] = currentline[j].split("â¬").join("€");
        }

        result.push(obj);
      }
    }

    return result;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export function fuzzyMatch(pattern: string, str: string): boolean {
  pattern = ".*" + pattern.split("").join(".*") + ".*";
  const re = new RegExp(pattern, "gi");
  return re.test(str);
}

export function setCookie(name: string, value: string, days: number) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
